import React from 'react'
import './Bookkeeping.css';

const Bookkeeping = () => {
    return (
        <>
            <section id="service" classNameName="service section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-title ">
                        <h2>Services</h2>
                        <h3>Bookkeeping Service Overview</h3>
                    </div>
                </div>
                {/* </section> */}

                {/* <section id="featured-services" className="featured-services"> */}
                <div className="container mt-3" data-aos="fade-up">

                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Introduction</h4>
                                <p className="description">Briefly introduce your bookkeeping service, highlighting your expertise, years of experience, and commitment to helping businesses manage their financial records effectively.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Experience</h4>
                                <p className="description">Provide information about your team's experience and qualifications, including any relevant certifications (e.g., Certified Public Accountant, Certified Bookkeeper).</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Technology</h4>
                                <p className="description">Highlight any accounting software you're proficient with (e.g., QuickBooks, Xero, FreshBooks) and mention your ability to leverage technology for accurate and efficient record-keeping.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Data Security</h4>
                                <p className="description"> Assure potential clients of your commitment to maintaining the confidentiality and security of their financial data.</p>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="container mt-5" data-aos="fade-up">

                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Client Types</h4>
                                <p className="description">Specify the types of clients you work with, whether it's small businesses, startups, freelancers, or larger enterprises.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Value Proposition</h4>
                                <p className="description">Explain how your bookkeeping services can benefit clients. This could include helping them save time, make informed financial decisions, stay compliant with tax regulations, and achieve better financial visibility.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Client Support</h4>
                                <p cl assName="description">Describe your approach to client communication and support. Whether it's regular check-ins, reporting, or being available to answer questions, make it clear how you provide ongoing assistance.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                <h4 className="title">Pricing</h4>
                                <p className="description">  While you might not want to provide exact pricing on your website, you can mention that you offer competitive and transparent pricing models customized based on the client's needs.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            

        </>
    )
}

export default Bookkeeping
