
import {Routes, Route} from "react-router-dom";
import './App.css';

import Navbar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/Footer'
import About from './Components/About/About'
import Contact from './Components/Contact/Contact'
import Home from './Components/Home/Home'
import Count from "./Components/Count/Count";
import Choose from "./Components/Choose/Choose";
import Service from "./Components/Service/Service";
import Hpcare from "./Components/Service/Hpcare";
import Beauty from "./Components/Service/Beauty";
import Babycare from "./Components/Service/babycare";
import Groceries from "./Components/Service/Groceries";
import ScrollToTop from './Components/Scroll/ScrollToTop';
import Bookkeeping from "./Components/Bookkeeping/Bookkeeping";
import Slideshow from "./Components/Slide/Slide";
function App() {
  return (
    <>
    <ScrollToTop />
    <Navbar />
    {/* <Slideshow /> */}

    <Routes>

      <Route path="/Home" element={[<Home />,<Count />]} />
      <Route path="/About" element={[<About />,<Choose />]} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Service" element={<Service />} />
      <Route path="/HPCare" element={<Hpcare />} />
      <Route path="/Beauty" element={<Beauty />} />
      <Route path="/Babycare" element={<Babycare />} />
      <Route path="/Groceries" element={<Groceries />} />
      <Route path="/Bookkeeping" element={<Bookkeeping />} />
      <Route path="/" element={[<Home />,<Count />]} />

    </Routes>

    <Footer />

    

  
    </>
  );
}

export default App;
