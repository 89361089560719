import React from 'react'
import './Subservice.css'


const Beauty = () => {
  return (
    <>
      <section id="service" className="service section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title mt-5">
            <h2>Services</h2>
            <h3>Beauty & Cosmetics</h3>
          </div>

          {/* <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, the Beauty & Cosmetics industry holds great importance in the present-day context, experiencing substantial growth and a significant rise of 5% in recent years.</h5>
          <h5 align="justify" style={{ 'text-align': 'justify' }}>Understanding the dynamic market needs, Wills International Ltd has emerged as a prominent wholesaler, specializing in a wide array of Beauty & Cosmetics services. Our team of enthusiastic professionals is committed to providing all the essential products and services required for enhancing personal beauty and aesthetics. With state-of-the-art facilities and efficient distribution channels, Wills International Ltd consistently sources premium Beauty & Cosmetics products from leading suppliers worldwide, offering competitive prices to our valued customers. We strive to meet the diverse beauty requirements of our clientele, ensuring they receive the finest products and services to look and feel their best.</h5> */}


          <div className="row mt-5" style={{ "border": "1px dotted", "padding": "50px" }}>
            <div className="col-lg-6">
              <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, the Beauty & Cosmetics industry holds great importance in the present-day context, experiencing substantial growth and a significant rise of 5% in recent years.</h5><br />
              <h5 align="justify" style={{ 'text-align': 'justify' }}>Understanding the dynamic market needs, Wills International Ltd has emerged as a prominent wholesaler, specializing in a wide array of Beauty & Cosmetics services. Our team of enthusiastic professionals is committed to providing all the essential products and services required for enhancing personal beauty and aesthetics. With state-of-the-art facilities and efficient distribution channels, Wills International Ltd consistently sources premium Beauty & Cosmetics products from leading suppliers worldwide, offering competitive prices to our valued customers. We strive to meet the diverse beauty requirements of our clientele, ensuring they receive the finest products and services to look and feel their best.</h5>

            </div>
            <div className="col-lg-1">
              <div className="vl"></div>
            </div>
            <div className="col-lg-5">

            </div>
          </div>
        </div>
      </section>

      <section id="clients" class="clients section-bg">
        <div class="container" >

          <div class="row" data-aos="zoom-in">

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/7.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/8.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/9.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/10.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/11.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/12.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/13.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/14.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/1.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/2.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/3.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/4.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/5.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/6.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/24.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/15.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/21.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/17.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/18.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/19.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/20.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/21.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/22.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/23.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/25.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/26.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/27.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/28.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/29.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Beauty/30.png" class="img-fluid" alt="" />
            </div>

          </div>

        </div>
      </section>

    </>
  )
}

export default Beauty
