import React from 'react'
import './About.css'

const About = () => {
  return (
    <>

      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>About</h2>
            <h3>Find Out More <span>About Us</span></h3>
            {/* <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p> */}
          </div>

          <p align="justify" style={{ 'text-align': 'justify' }}><b>Wills International Ltd. belongs to an emerging establishment in the market with a group of enthusiastic people having extensive experience in the sector of Healthcare, Baby care, Groceries, FMCG and so on to position its name in the market.</b></p>
          <p align="justify" style={{ 'text-align': 'justify' }}>We are a business sector based in Watford, United Kingdom. Since our establishment, Wills International Ltd. has been exploring for the most optimal ways to achieve customer satisfaction, our objective is to gain customer satisfaction and possess long term business relationships.</p>
          <p align="justify" style={{ 'text-align': 'justify' }}>We, at Wills International Ltd., have a wide network of suppliers and an ideal infrastructure permitting to cover all the needs of our clients, and our team of specialists have extensive experience in the global market which allows us to negotiate import and export regulations fairly and efficiently, ensuring that you get the supplies you need, in minimal lead time. Our policy is to treat each client individually with an assigned team in order to meet the customer's requirement and expectations and the products are offered on a wholesale basis / as per the requirement at strong competitive prices.</p>
          <p align="justify" style={{ 'text-align': 'justify' }}>Our supply network has the most popular brands, offering Health &amp; Personal care products, Baby &amp; Child care products, Beauty &amp; Cosmetics, Groceries &amp; Toiletries, Fast Moving Consumer Goods (FMCG) and much more through our B2B network.</p>
          <p align="justify" style={{ 'text-align': 'justify' }}>With our enthusiastic workforce experienced in the field of Supply Chain Management; we offer our customer the product they need at the right time i.e., the brands that are recognized globally irrespective of the location of the client.&nbsp; We can reach you by all means of transportation viz. by land, by sea, and also by air freight. Let there be no limits in between a good business relationship.</p>

        </div>
      </section>
      {/* <!-- End About Section --> */}


    </>
  )
}

export default About
