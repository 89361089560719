import React from 'react'
import { NavLink } from 'react-router-dom'
import './Home.css'


const Home = () => {
  return (
    <>

      {/* <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <h1>Welcome to <span>Wills International</span></h1>
          <h2>We are team of talented designers making websites with Bootstrap</h2>
          <div className="d-flex">
            <NavLink to="/About" className="btn-get-started scrollto">About Us</NavLink>
          </div>
        </div>
      </section> */}

      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            {/* <img src="assets/img/slides-1.jpg" class="d-block w-100" alt="..."> */}
            <section id="hero" className="d-flex align-items-center">
              <div className="container" data-aos="zoom-out" data-aos-delay="100">
                <h1>Welcome to <span>Wills International</span></h1>
                <h2>We are team of talented designers making websites with Bootstrap</h2>
                <div className="d-flex">
                  <NavLink to="/About" className="btn-get-started scrollto">About Us</NavLink>
                </div>
              </div>
            </section>
          </div>
          <div class="carousel-item">
            {/* <img src="assets/img/slides-2.jpg" class="d-block w-100" alt="..."> */}
            <section id="hero" className="d-flex align-items-center">
              <div className="container" data-aos="zoom-out" data-aos-delay="100">
                <h1>Hello to <span>Wills International</span></h1>
                <h2>We are team of talented designers making websites with Bootstrap</h2>
                <div className="d-flex">
                  <NavLink to="/About" className="btn-get-started scrollto">About Us</NavLink>
                </div>
              </div>
            </section>
          </div>
          <div class="carousel-item">
            {/* <img src="assets/img/slides-3.jpg" class="d-block w-100" alt="..."> */}
            <section id="hero" className="d-flex align-items-center">
              <div className="container" data-aos="zoom-out" data-aos-delay="100">
                <h1>Namaste to <span>Wills International</span></h1>
                <h2>We are team of talented designers making websites with Bootstrap</h2>
                <div className="d-flex">
                  <NavLink to="/About" className="btn-get-started scrollto">About Us</NavLink>
                </div>
              </div>
            </section>
          </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>

      </div>
    </>
  )
}

export default Home
