import React from 'react'
import './Subservice.css'

const Hpcare = () => {
  return (
    <>

      <section id="service" className="service section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <h3>Health & Personal Care</h3>
          </div>

          <div className="row mt-5" style={{"border":"1px dotted","padding":"50px"}}>
            <div className="col-lg-6">

            </div>
            <div className="col-lg-1">
              <div className="vl"></div>
            </div>
            <div className="col-lg-5">
              <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, the significance of Health & Personal Care services in the contemporary context cannot be overstated, with a remarkable growth rate and a steep rise of 5% in recent years.</h5><br/>
              <h5 align="justify" style={{ 'text-align': 'justify' }}>Recognizing the evolving demands of the market, Wills International Ltd has emerged as a prominent wholesaler specializing in a comprehensive range of Health & Personal Care services. Comprising a team of dedicated and passionate individuals, Wills International Ltd strives to offer all the essential products and services needed for personal well-being. With state-of-the-art facilities and efficient distribution channels, we consistently source top-notch Health & Personal Care products and services from leading suppliers worldwide, ensuring competitive prices for our valued customers.</h5>

            </div>
          </div>

        </div>
      </section>



      <section id="clients" class="clients section-bg">
        <div class="container" >

          <div class="row" data-aos="zoom-in">

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/07.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/08.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/09.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/10.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/11.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/12.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/19.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/18.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/17.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/16.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/15.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/14.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/13.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/05.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/20.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/21.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/22.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/23.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/24.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/32.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/31.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/30.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/29.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/28.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/27.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/26.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/25.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/02.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/01.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/04.png" class="img-fluid" alt="" />
            </div>


            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/06.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Hpcare/03.png" class="img-fluid" alt="" />
            </div>

          </div>

        </div>
      </section>


    </>
  )
}

export default Hpcare
