import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.css'


const select = (el, all = false) => {
  el = el.trim();

  if (el.startsWith('/')) {
    el = el.substring(1);
  }

  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

const select1 = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

const scrollto = (hash) => {
  let header = select('#header');
  let offset = header.offsetHeight;

  let cleanedHash = hash.replace('/', ''); // Remove the leading forward slash
  let element = select(cleanedHash);

  if (element) {
    let elementPos = element.offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth',
    });
  }
};

const Navbar = () => {

  useEffect(() => {


    // moble navbar active
    const handleClick = (e) => {
      const clickedElement = e.target;
      const navbar = select('#navbar');

      if (clickedElement.matches('.mobile-nav-toggle')) {
        navbar.classList.toggle('navbar-mobile');
        clickedElement.classList.toggle('bi-list');
        clickedElement.classList.toggle('bi-x');
      }

      if (clickedElement.matches('.navbar a')) {
        navbar.classList.remove('navbar-mobile');
        let navbarToggle = select('.mobile-nav-toggle');
        navbarToggle.classList.toggle('bi-list');
        navbarToggle.classList.toggle('bi-x');
      }

      if (clickedElement.matches('.navbar .dropdown > .abc')) {
        const navbar = select('#navbar');

        if (navbar.classList.contains('navbar-mobile')) {
          e.preventDefault();
          clickedElement.nextElementSibling.classList.toggle('dropdown-active');
        }
      }

      if (clickedElement.matches('.scrollto')) {
        const targetHash = clickedElement.getAttribute('to');

        if (targetHash && select(targetHash)) {
          e.preventDefault();

          let navbar = select('#navbar');

          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            let navbarToggle = select('.mobile-nav-toggle');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
          }

          scrollto(targetHash);
        }
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };



  }, []);

  useEffect(() => {
    // sticked navbar

    const selectHeader = select1('#header');
    const nextElement = selectHeader?.nextElementSibling;
    let headerOffset = selectHeader?.offsetTop || 0;

    const handleHeaderFixed = () => {
      if ((headerOffset - window.scrollY) <= 0) {
        selectHeader?.classList.add('fixed-top');
        nextElement?.classList.add('scrolled-offset');
      } else {
        selectHeader?.classList.remove('fixed-top');
        nextElement?.classList.remove('scrolled-offset');
      }
    };

    handleHeaderFixed(); // Call it initially to set the initial state
    window.addEventListener('load', handleHeaderFixed);
    document.addEventListener('scroll', handleHeaderFixed);

    return () => {
      window.removeEventListener('load', handleHeaderFixed);
      document.removeEventListener('scroll', handleHeaderFixed);
    };
  }, [])

  return (
    <>

      {/* <!-- ======= Top Bar ======= --> */}
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center"><NavLink>info@willsinternational.co.uk</NavLink></i>
            <i className="bi bi-phone d-flex align-items-center ms-4"><span>+44 7469453383</span></i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <NavLink to="#" className="twitter"><i className="bi bi-twitter"></i></NavLink>
            <NavLink to="#" className="facebook"><i className="bi bi-facebook"></i></NavLink>
            <NavLink to="#" className="instagram"><i className="bi bi-instagram"></i></NavLink>
            <NavLink to="#" className="linkedin"><i className="bi bi-linkedin"></i></NavLink>
          </div>
        </div>
      </section>

      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center ">

          <img src="assets/img/wills/wills.png" alt="hello" className="wills" />
          <h1 className="logo"><NavLink to="/">Wills International<span>.</span></NavLink></h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li><NavLink className="nav-link scrollto" to="/Home">Home</NavLink></li>
              <li><NavLink className="nav-link scrollto" to="/About">About</NavLink></li>
              <li className="dropdown"><NavLink to="/Service"><span>Services</span> <i className="bi bi-chevron-down"></i></NavLink>
                <ul>
                  <li><NavLink to="/HPCare">Health & Personal Care</NavLink></li>
                  <li><NavLink to="/Beauty">Beauty & Cosmetics</NavLink></li>
                  <li><NavLink to="/Babycare">Baby & Child Care</NavLink></li>
                  <li><NavLink to="/Groceries">Groceries & Toiletries</NavLink></li>
                </ul>
              </li>
              <li><NavLink className="nav-link scrollto" to="/Career">Career</NavLink></li>
              <li><NavLink className="nav-link scrollto" to="/Contact">Contact</NavLink></li>
              <li className="section-title">
                <NavLink className="nav-link scrollto bookkeeping"  to="/Bookkeeping"><h2>Book Keeping Service</h2></NavLink>
              </li>
              {/* <li><NavLink className="nav-link scrollto" to="#contact">Contact</NavLink></li> */}
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}

        </div>
      </header>
      {/* <!-- End Header --> */}


    </>
  )
}

export default Navbar
