import React from 'react'
import { NavLink } from 'react-router-dom'
import './Choose.css'

const Choose = () => {
  return (
    <>
      {/* <!-- ======= Why Us Section ======= --> */}
    <section id="why-us" className="why-us section-bg">
      <div className="container" data-aos="fade-up">

        <div className="row gy-4">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="why-box">
              <h3>Why Choose <br />Wills International?</h3>
              <p>
              choosing Wills International means gaining access to a reliable, customer-centric wholesaler with an extensive product range, unwavering commitment to quality, competitive prices, and a focus on sustainability. We aim to be your trusted partner in meeting your various needs, ensuring your satisfaction and convenience every step of the way.
              </p>
              <div className="text-center">
                <NavLink to="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></NavLink>
              </div>
            </div>
          </div>
          {/* <!-- End Why Box --> */}

          <div className="col-lg-8 d-flex align-items-center">
            <div className="row gy-4">

              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-clipboard-data"></i>
                  <h4>Extensive Product Range</h4>
                  <p>Wills International offers a comprehensive range of products in various categories, ensuring that you can find everything you need in one place. Whether it's health care products, beauty & cosmetics, groceries & toiletries, or baby & child care services, we've got you covered.</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-gem"></i>
                  <h4>Quality Assurance</h4>
                  <p>We prioritize quality in all our offerings. Our products are sourced from leading suppliers worldwide, and we maintain strict quality control measures to ensure that you receive only the best.</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

              <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-inboxes"></i>
                  <h4>Competitive Prices</h4>
                  <p>Despite offering high-quality products and services, we strive to keep our prices competitive. We believe that everyone should have access to essential items without breaking the bank.</p>
                </div>
              </div>
              {/* <!-- End Icon Box --> */}

            </div>
          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Why Us Section --> */}
    </>
  )
}

export default Choose
