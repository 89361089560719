import React from 'react'
import './Subservice.css'


const Babycare = () => {
  return (
    <>
      <section id="service" className="service section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <h3>Baby & Child Care</h3>
          </div>

          {/* <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, Baby & Child Care Services have gained immense significance in the present-day context, witnessing substantial growth and a steep rise of 5% in recent years.</h5>
          <h5 align="justify" style={{ 'text-align': 'justify' }}>Understanding the evolving demands of parents and caregivers, Wills International Ltd has emerged as a prominent wholesaler, specializing in a comprehensive range of Baby & Child Care Services. With a team of enthusiastic individuals, we are dedicated to providing all the essential products and services needed for the well-being and nurturing of infants and children. Leveraging our state-of-the-art facilities and efficient distribution channels, Wills International Ltd consistently sources top-quality Baby & Child Care products from leading suppliers worldwide, ensuring competitive prices for our valued customers. We are committed to meeting the diverse needs of parents and caregivers, ensuring they have access to premium products and services that contribute to the health and happiness of their little ones.</h5> */}

          <div className="row mt-5" style={{ "border": "1px dotted", "padding": "50px" }}>
            <div className="col-lg-6">

            </div>
            <div className="col-lg-1">
              <div className="vl"></div>
            </div>
            <div className="col-lg-5">
              <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, Baby & Child Care Services have gained immense significance in the present-day context, witnessing substantial growth and a steep rise of 5% in recent years.</h5><br />
              <h5 align="justify" style={{ 'text-align': 'justify' }}>Understanding the evolving demands of parents and caregivers, Wills International Ltd has emerged as a prominent wholesaler, specializing in a comprehensive range of Baby & Child Care Services. With a team of enthusiastic individuals, we are dedicated to providing all the essential products and services needed for the well-being and nurturing of infants and children. Leveraging our state-of-the-art facilities and efficient distribution channels, Wills International Ltd consistently sources top-quality Baby & Child Care products from leading suppliers worldwide, ensuring competitive prices for our valued customers. We are committed to meeting the diverse needs of parents and caregivers, ensuring they have access to premium products and services that contribute to the health and happiness of their little ones.</h5>

            </div>
          </div>

        </div>
      </section>

      <section id="clients" class="clients section-bg">
        <div class="container" >

          <div class="row" data-aos="zoom-in">

            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/07.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/08.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/09.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/10.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/11.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/12.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/13.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/14.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/01.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/02.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/03.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/04.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/05.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/06-.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/24.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/15.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/21.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/17.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/18.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/19.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/20.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/21.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/22.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/Service/Babycare/23.png" class="img-fluid" alt="" />
            </div>

          </div>

        </div>
      </section>
    </>
  )
}

export default Babycare
