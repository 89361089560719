import React from 'react'
import './Subservice.css'


const Groceries = () => {
  return (
    <>
      <section id="service" className="service section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title mt-5">
            <h2>Services</h2>
            <h3>Groceries & Toiletries</h3>
          </div>


          <div className="row mt-5" style={{ "border": "1px dotted", "padding": "50px" }}>
            <div className="col-lg-6">
              <h5 align="justify" style={{ 'text-align': 'justify' }}>In the UK, Groceries & Toiletries Services play a crucial role in the contemporary context, witnessing a remarkable growth rate and a steep rise of 5% in recent years.</h5> <br />
              <h5 align="justify" style={{ 'text-align': 'justify' }}>Recognizing the evolving needs of the market, Wills International Ltd has emerged as a prominent wholesaler, specializing in a comprehensive range of Groceries & Toiletries Services. With a team of enthusiastic individuals, we are dedicated to providing all the essential products and services needed for daily living and personal care. Leveraging our state-of-the-art facilities and efficient distribution channels, Wills International Ltd consistently sources high-quality Groceries & Toiletries products from leading suppliers worldwide, ensuring competitive prices for our valued customers. We are committed to meeting the diverse requirements of our clientele, ensuring they have access to top-notch products and services that enhance their daily lives and well-being.</h5>

            </div>

            <div className="col-lg-1">
              <div className="vl"></div>
            </div>

            <div className="col-lg-5">

            </div>
          </div>

        </div>
      </section>

    </>
  )
}

export default Groceries
