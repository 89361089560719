import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <>

      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Contact</h2>
            <h3><span>Contact Us</span></h3>
            {/* <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p> */}
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <i className="bx bx-map"></i>
                <h3>Our Address</h3><br />
                <div className="row" >
                  <div className="col-lg-6">

                    <p> 1060A, LONDON ROAD, <br />
                      THORNTON HEATH CROYDON, <br />
                      SURREY (CR7 7ND), United Kingdom</p>
                  </div>
                  <div className="col-lg-6">

                    <p>A-315, SANKET TOWER, <br />
                      2nd FLOOR, NR. GRID CHOKDI,<br />
                      ANAND-388001, GUJARAT, INDIA</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="bx bx-envelope"></i>
                <h3>Email Us</h3><br />
                <p>info@willsinternational.co.uk</p><br /><br />
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i className="bx bx-phone-call"></i>
                <h3>Call Us</h3><br />
                <p>+44 7469453383</p>
                <p>+91 8485976121</p><br />
              </div>
            </div>

          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">

            <div className="col-lg-6 ">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2489.097511161788!2d-0.12058192360521765!3d51.40126297178941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487606e83a3aa4f3%3A0xd641f351fab4dee9!2s1060%20London%20Rd%2C%20Thornton%20Heath%20CR7%207ND%2C%20UK!5e0!3m2!1sen!2sin!4v1691001195022!5m2!1sen!2sin" style={{ 'border': '0', 'width': '100%', 'height': '384px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-lg-6 ">
              {/* <iframe className="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style={{ 'border': '0', 'width': '100%', 'height': '384px' }} allowfullscreen></iframe> */}
              <iframe  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14738.52588996277!2d72.9504397!3d22.5554738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4ddd937ce627%3A0xd76b2621ee175175!2sPatel%20%26%20Shah%20E-Commerce%20Consultancy!5e0!3m2!1sen!2sin!4v1690018355984!5m2!1sen!2sin" style={{ 'border': '0', 'width': '100%', 'height': '384px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>


          </div>


          <div className="col-lg-12 mt-3">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>
      </section>
      {/* <!-- End Contact Section --> */}


    </>
  )
}

export default Contact
