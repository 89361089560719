import React from 'react'
import {NavLink} from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <>

      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer">

        

          <div className="footer-top">
            <div className="container">
              <div className="row">

                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Wills International Ltd <span>.</span></h3>
                  <p>
                  1060A, LONDON ROAD, <br/>
                  THORNTON HEATH CROYDON, <br/>
                  SURREY (CR7 7ND), <br/>
                  United Kingdom<br/><br/>
                          <strong>Phone  :  </strong>  +44 7469453383<br/>
                            <strong>Email  :  </strong>  info@willsinternational.co.uk<br/>
                            Company Registrar Number  :  14188313
                            </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>Wills International<span>.</span></h3>
                  <p>
                  A-315, SANKET TOWER, <br/>
                  2nd FLOOR, NR. GRID CHOKDI,<br/>  
                  ANAND-388001,<br/>
                   GUJARAT, INDIA<br/><br/>
                          <strong>Phone  :  </strong>  +91 8485976121<br/>
                            <strong>Email  :  </strong>  info@willsinternational.co.uk<br/>
                            </p>
                </div>

                          <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Home">Home</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="/About">About</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Service">Services</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Career">Career</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="/Contact">Contact</NavLink></li>
                            </ul>
                          </div>

                          <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="#">Web Design</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="#">Web Development</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="#">Product Management</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="#">Marketing</NavLink></li>
                              <li><i className="bx bx-chevron-right"></i> <NavLink to="#">Graphic Design</NavLink></li>
                            </ul>
                          </div>

                          {/* <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
                            <div className="social-links mt-3">
                              <NavLink to="#" className="twitter"><i className="bx bxl-twitter"></i></NavLink>
                              <NavLink to="#" className="facebook"><i className="bx bxl-facebook"></i></NavLink>
                              <NavLink to="#" className="instagram"><i className="bx bxl-instagram"></i></NavLink>
                              <NavLink to="#" className="google-plus"><i className="bx bxl-skype"></i></NavLink>
                              <NavLink to="#" className="linkedin"><i className="bx bxl-linkedin"></i></NavLink>
                            </div>
                          </div> */}

                        </div>
                        </div>
                      </div>

                      <div className="container py-4">
                        <div className="copyright">
                          &copy; Copyright <strong><span>Wills International</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                          Designed by <NavLink to="https://www.linkedin.com/in/megh-shah-a19813205" target="_blank">Megh Shah</NavLink>
                        </div>
                      </div>
                    </footer>
                    {/* <!-- End Footer --> */}

    </>
  )
}

export default Footer
